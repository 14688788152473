<template>
  <div>
    <div v-if="loading">
      <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
        <div class="spinner-border text-success align-self-center loader-lg" />
      </div>
    </div>
    <div v-else-if="loadingError" class="cm-loading-error">
      {{ $t("calendarModule.errors.loadingError") }}
    </div>
    <div v-else>
      <b-row>
        <b-col v-if="(areSlotsGiven && !withEdit) || isFrontPage">
          <b-form-group
            :description="$t('calendarModule.slotList.descriptions.search')"
          >
            <template v-slot:label>
              {{ $t("calendarModule.slotList.labels.search") }}
            </template>
            <b-input v-model="searchText" />
          </b-form-group>
        </b-col>
        <b-col v-if="areSlotsGiven && !withEdit">
          <b-form-group
            :description="$t('calendarModule.slots.descriptions.freeFilter')"
          >
            <template v-slot:label>
              {{ $t("calendarModule.slots.labels.allFilter") }}
            </template>
            <v-select
              :options="freeOptionsChosenDate"
              v-model="localFreeFilterChosenDate"
              :reduce="o => o.code"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div v-if="!areSlotsGiven">
        <!-- Filtering: date, free, calendar -->
        <b-row v-if="!isMobileScreenSize">
          <b-col v-if="!isFromCalendarModuleSettings && isTheSlotsEmpty">
            <b-form-group
              :description="
                $t('calendarModule.slotList.descriptions.filterDate')
              "
              class="has-float-label"
            >
              <template v-slot:label>
                {{ $t("calendarModule.slotList.labels.filterDate") }}
              </template>
              <date-picker
                class="w-100"
                format="DD.MM.YYYY"
                input-class="form-control pac-target-input"
                v-model="filterDate"
                @close="filterDateChanged"
              ></date-picker>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              :description="$t('calendarModule.slots.descriptions.freeFilter')"
              class="has-float-label"
            >
              <template v-slot:label>
                {{ $t("calendarModule.slots.labels.allFilter") }}
              </template>
              <v-select
                :options="freeOptions"
                v-model="localFreeFilter"
                :reduce="o => o.code"
                @input="() => this.loadSlots()"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="calendars.length > 1">
            <CalendarDropdown
              :theCalendar="calendar"
              :theCalendars="calendars"
              @changedSelection="selectCalendar"
            />
          </b-col>
        </b-row>
        <!-- Filtering: date, free, calendar -->
        <b-row v-else>
          <b-col v-if="!isFromCalendarModuleSettings && isTheSlotsEmpty">
            <b-form-group
              :description="
                $t('calendarModule.slotList.descriptions.filterDate')
              "
              class="has-float-label"
            >
              <template v-slot:label>
                {{ $t("calendarModule.slotList.labels.filterDate") }}
              </template>
              <date-picker
                class="w-100"
                format="DD.MM.YYYY"
                input-class="form-control pac-target-input"
                v-model="filterDate"
                @close="filterDateChanged"
              ></date-picker>
            </b-form-group>
            <b-form-group
              :description="$t('calendarModule.slots.descriptions.freeFilter')"
              class="has-float-label"
            >
              <template v-slot:label>
                {{ $t("calendarModule.slots.labels.allFilter") }}
              </template>
              <v-select
                :options="freeOptions"
                v-model="localFreeFilter"
                :reduce="o => o.code"
                @input="() => this.loadSlots()"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="calendars.length > 1">
            <CalendarDropdown
              :theCalendar="calendar"
              :theCalendars="calendars"
              @changedSelection="selectCalendar"
            />
          </b-col>
        </b-row>
      </div>
      <div class="mt-4">
        <button
          @click="openStatusInformationModal()"
          class="btn btn-outline-primary mb-3"
        >
          {{ $t("soulLetters.status.titleInformation") }}
        </button>
      </div>
      <div class="table-responsive mt-5">
        <perfect-scrollbar>
          <SlotListTable
            :is-mobile-screen-size="isMobileScreenSize"
            :is-modal-mobile-screen-size="isModalMobileScreenSize"
            :is-tablet-screen-size="isTabletScreenSize"
            :is-sm-desktop-screen-size="isSmDesktopScreenSize"
            :edit-in-list="editInList"
            :with-edit="withEdit"
            :shown-slots="shownSlots"
            :local-free-filter="localFreeFilter"
            :show-client-contact-details-modal="showClientContactDetailsModal"
            :show-edit-modal="showEditModal"
            :open-edit-appointment-modal="openEditAppointmentModal"
            :open-conversation-details-modal="openConversationDetailsModal"
            :open-cancel-appointment-modal="openCancelAppointmentModal"
          />
        </perfect-scrollbar>
      </div>
    </div>
    <!-- Show Client Contact Details Modal -->
    <b-modal
      :title="$t('soulLetter.historyTitle')"
      ref="conversationDetailsModal"
      size="lg"
      hide-footer
      onclose="closeConversationDetailsModal()"
    >
      <b-row>
        <b-col>
          <ConversationSummary :conversation-slot="chosenHistoryConversation" />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <button
            @click="closeConversationDetailsModal"
            class="btn btn-primary mb-2 mr-2"
          >
            {{ $t("misc.close") }}
          </button>
        </b-col>
      </b-row>
    </b-modal>
    <!-- Conversation Details Modal -->
    <b-modal
      :title="$t('profile.contactInformation.title')"
      ref="showClientContactDetailsModal"
      size="xl"
      hide-footer
      onclose="closeClientContactDetailsModal()"
    >
      <div v-if="loadingClientData">
        <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
          <div
            class="spinner-border text-success align-self-center loader-lg"
          />
        </div>
      </div>
      <div v-else-if="loadingClientDataError" class="cm-loading-error m-4">
        {{ $t("calendarModule.errors.loadingError") }}
      </div>
      <div v-else>
        <div v-if="clientContactInformation">
          <b-row class="mb-4 text-center">
            <b-col>
              <h6 class="font-weight-bold">
                {{ $t("profile.contactInformation.description") }}:
              </h6>
            </b-col>
          </b-row>
          <table class="ml-auto mr-auto">
            <tr v-if="clientContactInformation.hasOwnProperty('givenName')">
              <td class="p-1">
                <span class="font-weight-bold">
                  {{ $t("profile.firstName") }}:
                </span>
              </td>
              <td class="p-1">
                {{ clientContactInformation.givenName }}
              </td>
            </tr>
            <tr v-if="clientContactInformation.hasOwnProperty('familyName')">
              <td class="p-1">
                <span class="font-weight-bold">
                  {{ $t("profile.lastName") }}:
                </span>
              </td>
              <td class="p-1">
                {{ clientContactInformation.familyName }}
              </td>
            </tr>
            <tr v-if="clientContactInformation.hasOwnProperty('email')">
              <td class="p-1">
                <span class="font-weight-bold">
                  {{ $t("profile.eMail") }}:
                </span>
              </td>
              <td class="p-1">
                {{ clientContactInformation.email }}
              </td>
            </tr>
            <tr v-if="clientContactInformation.hasOwnProperty('phoneNumber')">
              <td class="p-1">
                <span class="font-weight-bold">
                  {{ $t("profile.telNr") }}:
                </span>
              </td>
              <td class="p-1">
                {{ clientContactInformation.phoneNumber }}
              </td>
            </tr>
          </table>
        </div>
        <div class="mt-5" v-if="currentUser.role !== EUserRoles.STUDENT">
          <ConversationHistoryTable
            :is-mobile-screen-size="isMobileScreenSize"
            :is-tablet-screen-size="isTabletScreenSize"
            :is-sm-desktop-screen-size="isSmDesktopScreenSize"
            :is-modal-mobile-screen-size="isModalMobileScreenSize"
            :history-conversations="historyConversations"
          />
          <SoulLetterHistoryTable
            :chosen-client="chosenClientSoulLetter"
            :is-mobile-screen-size="isMobileScreenSize"
            :is-tablet-screen-size="isTabletScreenSize"
            :is-sm-desktop-screen-size="isSmDesktopScreenSize"
            :is-modal-mobile-screen-size="isModalMobileScreenSize"
            :history-soul-letters="historySoulLetters"
          />
        </div>
      </div>
      <b-row class="mt-5">
        <b-col>
          <button
            @click="closeClientContactDetailsModal"
            class="btn btn-primary mb-2 mr-2"
          >
            {{ $t("misc.close") }}
          </button>
        </b-col>
      </b-row>
    </b-modal>
    <!-- Edit Slot Modal -->
    <b-modal
      ref="editModal"
      size="xl"
      @hide="slotFormOpen = false"
      :title="$t('calendarModule.modalTitles.editSlot')"
      hide-footer
    >
      <MassSlotsActions
        v-if="massModalSlots"
        :slots="massModalSlots"
        :is-edit="true"
        @slotsChanged="slotsChanged"
      />
    </b-modal>
    <!-- Edit Appointment Modal -->
    <b-modal
      ref="editAppointmentModal"
      size="xl"
      @hide="isEditAppointment = false"
      :title="$t('calendarModule.modalTitles.editAppointment')"
      hide-footer
    >
      <div>
        <div v-if="conversationForClientAction">
          <BookingSelectionCalendar
            :companion-for-edit="conversationForClientAction.host"
            :is-edit-appointment="true"
            :is-change-from-companion="true"
            :edit-appointment-chosen-conversation="conversationForClientAction"
            :key="isReloadBookingSelection"
            @conversationSavedSuccess="conversationSavedSuccess"
            @conversationSavedError="conversationSavedError"
          />
        </div>
      </div>
    </b-modal>
    <!-- Cancel Appointment Modal -->
    <b-modal
      ref="cancelAppointmentModal"
      size="xl"
      @hide="isCancelAppointment = false"
      :title="$t('calendarModule.modalTitles.cancelAppointment')"
      hide-footer
    >
      <b-row>
        <b-col>
          <p>{{ $t("conversations.cancelAppointmentDescription") }}</p>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <button
            @click="commitCancelAppointmentModal"
            class="btn btn-outline-danger mb-2 mr-2"
          >
            {{ $t("conversations.cancelAppointment") }}
          </button>
          <button
            @click="closeCancelAppointmentModal"
            class="btn btn-outline-primary mb-2 mr-2"
          >
            {{ $t("misc.close") }}
          </button>
        </b-col>
      </b-row>
    </b-modal>
    <!-- Status Information Modal -->
    <b-modal
      :title="$t('soulLetters.status.titleInformation')"
      ref="statusInformationModal"
      hide-footer
      size="md"
      onclose="closeStatusInformationModal()"
    >
      <div v-if="currentUser">
        <table class="table-vertical-align">
          <tbody>
            <tr>
              <td class="p-2">
                <b-badge class="bg-warning font-size-2 shadow-none">
                  {{ $t("conversations.status.scheduled") }}
                </b-badge>
              </td>
              <td class="p-2">
                <span>
                  {{ $t("conversations.status.scheduledExplanationCompanion") }}
                </span>
              </td>
            </tr>
            <tr class="border-top border-grey">
              <td class="p-2">
                <b-badge class="bg-danger shadow-none">
                  {{ $t("conversations.status.unpaidCompanion") }}
                </b-badge>
              </td>
              <td class="p-2">
                <span>
                  {{
                    $t(
                      "conversations.status.unpaidCompanionExplanationCompanion"
                    )
                  }}
                </span>
              </td>
            </tr>
            <tr class="border-top border-grey">
              <td class="p-2">
                <b-badge class="bg-success shadow-none">
                  {{ $t("conversations.status.completed") }}
                </b-badge>
              </td>
              <td class="p-2">
                <span>
                  {{ $t("conversations.status.completedExplanationCompanion") }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <button
        @click="closeStatusInformationModal()"
        class="btn btn-outline-success mb-2 mr-2 mt-4"
      >
        {{ $t("misc.close") }}
      </button>
    </b-modal>
  </div>
</template>

<script>
import * as cmApi from "../../../store/modules/calendarModule/calendarModule.api";
import * as userApi from "../../../store/modules/users/users.api";
import * as conversationsApi from "../../../store/modules/conversations/conversations.api";
import * as moment from "moment";
import DatePicker from "vue2-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue2-datepicker/locale/de";
import "vue2-datepicker/index.css";
import { mapGetters } from "vuex";
import ConversationSummary from "../../conversations/ConversationSummary";
import { convertToLocal } from "../../../utils/timezoneHelper";
import { displayUsernameToEmail } from "../../../utils/userHelper";
import SlotListTable from "@/components/calendarModule/common/SlotListTable";
import screenSizeMixin from "@/mixins/screenSize";
import SoulLetterHistoryTable from "@/components/soulLetters/SoulLetterHistoryTable";
import ConversationHistoryTable from "@/components/conversations/ConversationHistoryTable";
import { EUserRoles } from "@/store/modules/users/EUserRoles";
import { EConversationTypes } from "../../../store/modules/conversations/EConversationTypes";

export default {
  name: "SlotList",
  components: {
    ConversationHistoryTable,
    SoulLetterHistoryTable,
    SlotListTable,
    ConversationSummary,
    MassSlotsActions: () =>
      import("../../../components/calendarModule/common/MassSlotsActions"),
    CalendarDropdown: () =>
      import("../../calendarModule/common/CalendarDropdown"),
    BookingSelectionCalendar: () =>
      import("../../../views/conversations/BookingSelectionCalendar"),
    // SlotInfo: () => import("../../calendarModule/common/SlotInfo"),
    vSelect,
    DatePicker
  },
  props: [
    "theCalendars",
    "theSlots",
    "settingForDate",
    "settingFree",
    "slotTypePrefix",
    "withEdit",
    "editInList",
    "withSelect",
    "withSelectPatientMetadata",
    "isFromCalendarModuleSettings",
    "isFrontPage"
  ],
  mixins: [screenSizeMixin],
  data() {
    return {
      loadingClientData: false,
      loadingClientDataError: false,
      EUserRoles,
      chosenClientSoulLetter: null,
      historyConversations: [],
      historySoulLetters: [],
      displayUsernameToEmail,
      moment,
      clientContactInformation: null,
      isEditAppointment: false,
      isCancelAppointment: false,
      cancelAppointment: null,
      isReloadBookingSelection: false,
      conversationForClientAction: null,
      allConversations: [],
      isTheSlotsEmpty: false,
      chosenHistoryConversation: null,
      massModalSlots: null,
      isReloadSlots: false,
      currentUser: null,
      cmApi,
      loading: false,
      loadingError: false,
      slots: [],
      areSlotsGiven: false,
      forDate: moment()
        .startOf("day")
        .toDate(),
      free: null,
      searchIdentifiers: {},
      localFreeFilter: null,
      localFreeFilterChosenDate: null,
      searchText: "",
      calendars: [],
      calendar: null,
      filterDate: null,
      patientsData: {
        patients: [],
        vaccinations: [],
        tests: []
      },
      dummyCalendarData: {
        _id: "be01508b-18b4-4d30-8412-00d08ceec90f",
        requiresPermission: false,
        defaultSlotsDaysBefore: 14,
        defaultSlotsExcludedDates: [],
        name: "Dummy Calendar",
        metadata: {
          color: "#73D8FF",
          aboveAddressText: "Anzeige Infotext Oberhalb",
          addressText: null,
          belowAddressText: "Anzeige Infotext Unterhalb",
          customFieldPermissions: null,
          isInactive: false
        },
        ownerId: "5af2a1f9-0cbe-4158-b53f-ae36b6c4ec7c",
        creationTimeStamp: "2021-06-23 06:13:16.839Z",
        updateTimeStamp: "2021-06-23 06:13:16.839Z",
        _v: 0
      },
      dummySlots: [
        {
          _id: "924f0f9c-3e28-472c-bccc-24ae089d8853",
          free: true,
          calendar: "be01508b-18b4-4d30-8412-00d08ceec90f",
          time: "12:00",
          endTime: "13:00",
          date: "2021-06-27",
          endDate: "2021-06-27",
          metadata: {
            /*  slotType: "conversation",
            followupType: "sonnestPossible",
            extraDescription: "Extra Beschreibungstext",
            massUuid: "5f9612c4-ac9a-4130-b6cf-e1ebc2ea658f"
          */
          },
          creationTimeStamp: "2021-06-23 06:20:42.806Z",
          updateTimeStamp: "2021-06-23 06:20:42.806Z"
        },
        {
          _id: "0f304172-de2e-494d-a4dc-5f39a87ac150",
          free: true,
          calendar: "be01508b-18b4-4d30-8412-00d08ceec90f",
          time: "13:00",
          endTime: "14:00",
          date: "2021-06-27",
          endDate: "2021-06-27",
          metadata: {
            /*  slotType: "conversation",
            followupType: "sonnestPossible",
            extraDescription: "Extra Beschreibungstext",
            massUuid: "5f9612c4-ac9a-4130-b6cf-e1ebc2ea658f"
          */
          },
          creationTimeStamp: "2021-06-23 06:20:42.844Z",
          updateTimeStamp: "2021-06-23 06:20:42.844Z"
        }
      ]
    };
  },
  computed: {
    ...mapGetters("users", ["getCurrentUser"]),
    bookedSlotsChosenDate() {
      return this.slots.filter(slot => slot.free === false);
    },
    freeSlotsChosenDate() {
      return this.slots.filter(slot => slot.free === true);
    },
    bookedSlots() {
      //return this.slots.filter(slot => slot.free === false);
      return this.slots.filter(slot => {
        if (
          moment(slot.date, "YYYY-MM-DD").diff(moment().format("YYYY-MM-DD")) >
          0
        ) {
          if (!slot.free) {
            return slot;
          }
        } else if (
          moment(slot.date, "YYYY-MM-DD").diff(
            moment().format("YYYY-MM-DD")
          ) === 0
        ) {
          const currentTimeString = moment().format("HH:mm");
          if (
            moment(slot.time, "HH:mm").diff(
              moment(currentTimeString, "HH:mm")
            ) > -1200000
          ) {
            if (!slot.free) {
              return slot;
            }
          }
        }
      });
    },
    freeSlots() {
      // return this.slots.filter(slot => slot.free === true);
      return this.slots.filter(slot => {
        if (
          moment(slot.date, "YYYY-MM-DD").diff(moment().format("YYYY-MM-DD")) >
          0
        ) {
          if (slot.free) {
            return slot;
          }
        } else if (
          moment(slot.date, "YYYY-MM-DD").diff(
            moment().format("YYYY-MM-DD")
          ) === 0
        ) {
          const currentTimeString = moment().format("HH:mm");
          if (
            moment(slot.time, "HH:mm").diff(
              moment(currentTimeString, "HH:mm")
            ) > 0
          ) {
            if (slot.free) {
              return slot;
            }
          }
        }
      });
    },
    pastSlots() {
      return this.slots.filter(slot => {
        if (
          moment(slot.date, "YYYY-MM-DD").diff(moment().format("YYYY-MM-DD")) <
          0
        ) {
          if (!slot.free) {
            return slot;
          }
        } else if (
          moment(slot.date, "YYYY-MM-DD").diff(
            moment().format("YYYY-MM-DD")
          ) === 0
        ) {
          const currentTimeString = moment().format("HH:mm");
          if (
            moment(slot.time, "HH:mm").diff(
              moment(currentTimeString, "HH:mm")
            ) < -1200000 // 1200000 = 20min
          ) {
            if (!slot.free) {
              return slot;
            }
          }
        }
      });
    },
    futureSlots() {
      return this.slots.filter(slot => {
        if (
          moment(slot.date, "YYYY-MM-DD").diff(moment().format("YYYY-MM-DD")) >
          0
        ) {
          if (!slot.free) {
            return slot;
          }
        } else if (
          moment(slot.date, "YYYY-MM-DD").diff(
            moment().format("YYYY-MM-DD")
          ) === 0
        ) {
          const currentTimeString = moment().format("HH:mm");
          if (
            moment(slot.time, "HH:mm").diff(
              moment(currentTimeString, "HH:mm")
            ) > -1200000
          ) {
            if (!slot.free) {
              return slot;
            }
          }
        }
      });
    },
    shownSlots() {
      const searchParts = this.searchText
        .toLowerCase()
        .trim()
        .split(" ");
      let hasParts = false;
      for (const searchPart of searchParts) {
        if (searchPart) {
          hasParts = true;
        }
      }
      let baseSlots = this.slots;
      if (this.localFreeFilterChosenDate === null) {
        this.$emit("freeFilterNull");
      }
      if (this.localFreeFilterChosenDate === false) {
        baseSlots = this.bookedSlotsChosenDate;
        this.$emit("freeFilterFalse");
      }
      if (this.localFreeFilterChosenDate === true) {
        baseSlots = this.freeSlotsChosenDate;
        this.$emit("freeFilterTrue");
      }

      if (this.localFreeFilter === false) {
        baseSlots = this.bookedSlots;
      } else if (this.localFreeFilter === true) {
        baseSlots = this.freeSlots;
      } else if (this.localFreeFilter === "past") {
        baseSlots = this.filterSlots(this.pastSlots);
        // baseSlots = this.pastSlots;
      } else if (this.localFreeFilter === "future") {
        baseSlots = this.futureSlots;
      }
      const baseSlotsWithConversations = [];
      if (
        this.allConversations.length > 0 ||
        this.localFreeFilter === true ||
        this.localFreeFilter === null
      ) {
        for (let i = 0; i < baseSlots.length; i++) {
          const slot = baseSlots[i];
          for (let j = 0; j < this.allConversations.length; j++) {
            const conversation = this.allConversations[j];
            if (!slot.free) {
              if (slot._id === conversation.appointment._id) {
                let zoomMeeting = null;
                if (conversation.zoomMeeting) {
                  zoomMeeting = {
                    _id: conversation.zoomMeeting._id,
                    startUrl: conversation.zoomMeeting.startUrl,
                    joinUrl: conversation.zoomMeeting.joinUrl,
                    duration: conversation.zoomMeeting.duration,
                    recordingReceived:
                      conversation.zoomMeeting.recordingReceived
                  };
                }
                const payload = {
                  date: slot.date,
                  endDate: slot.endDate,
                  time: slot.time,
                  endTime: slot.endTime,
                  free: slot.free,
                  _id: slot._id,
                  type: conversation.type,
                  metadata: slot.metadata,
                  zoomMeeting: zoomMeeting,
                  status: conversation.status,
                  comment: conversation.subject,
                  companion: conversation.host.profile.name,
                  client: conversation.attendees[0].profile.name,
                  conversation: conversation
                };
                baseSlotsWithConversations.push(payload);
              }
            }
          }

          if (slot.free) {
            const payload = {
              date: slot.date,
              endDate: slot.endDate,
              time: slot.time,
              endTime: slot.endTime,
              free: slot.free,
              _id: slot._id,
              metadata: slot.metadata,
              status: null,
              comment: "-",
              companion: "-",
              client: "-"
            };
            baseSlotsWithConversations.push(payload);
          }
        }
      }

      if (hasParts) {
        return this.filterSlotListSearchField(
          baseSlotsWithConversations,
          searchParts
        );
      }

      return baseSlotsWithConversations;
    },
    freeOptionsChosenDate() {
      return [
        {
          label: this.$t("calendarModule.slots.freeOptions.all"),
          code: null
        },
        {
          label: this.$t("calendarModule.slots.freeOptions.free"),
          code: true
        },
        {
          label: this.$t("calendarModule.slots.freeOptions.notFree"),
          code: false
        }
      ];
    },
    freeOptions() {
      return [
        /*  {
          label: this.$t("calendarModule.slots.freeOptions.all"),
          code: null
        },*/
        {
          label: this.$t("calendarModule.slots.freeOptions.free"),
          code: true
        },
        /* {
           label: this.$t("calendarModule.slots.freeOptions.notFree"),
           code: false
         },*/
        {
          label: this.$t("calendarModule.slots.freeOptions.past"),
          code: "past"
        },
        {
          label: this.$t("calendarModule.slots.freeOptions.future"),
          code: "future"
        }
      ];
    }
  },
  methods: {
    closeStatusInformationModal() {
      this.$refs["statusInformationModal"].hide();
    },
    openStatusInformationModal() {
      this.$refs["statusInformationModal"].show();
    },
    showClientContactDetailsModal(slot) {
      this.loadingClientData = true;
      this.loadingClientDataError = false;
      userApi.getUser(slot.conversation.attendees[0]._id).then(res => {
        const user = res.data.data;
        this.clientContactInformation = user.profile;
        const clientPayload = {
          familyName: user.profile.familyName,
          givenName: user.profile.givenName,
          name: user.profile.name,
          profileImage: user.profile.picture,
          role: user.role,
          _id: user._id
        };
        this.chosenClientSoulLetter = clientPayload;

        userApi
          .getUserHistory(slot.conversation.attendees[0]._id)
          .then(res => {
            const userHistory = res.data.data;
            this.historyConversations = [];
            this.historySoulLetters = [];
            this.historySoulLetters = userHistory.letters;

            userHistory.conversations.forEach(conversation => {
              const convertedUtc = convertToLocal(
                conversation.appointment.date,
                conversation.appointment.endDate,
                conversation.appointment.time,
                conversation.appointment.endTime
              );
              const formattedAppointment = {
                date: convertedUtc.date,
                endDate: convertedUtc.endDate,
                time: convertedUtc.time,
                endTime: convertedUtc.endTime,
                _id: conversation.appointment._id
              };
              const toDay = moment();
              if (
                moment(formattedAppointment.date, "YYYY-MM-DD").diff(
                  moment(toDay, "YYYY-MM-DD")
                ) <= 0
              ) {
                let formattedConversation;
                if (conversation.zoomMeeting) {
                  formattedConversation = {
                    appointment: formattedAppointment,
                    attendees: conversation.attendees,
                    host: conversation.host,
                    subject: conversation.subject,
                    zoomMeeting: conversation.zoomMeeting,
                    _id: conversation._id
                  };
                } else {
                  formattedConversation = {
                    appointment: formattedAppointment,
                    attendees: conversation.attendees,
                    host: conversation.host,
                    subject: conversation.subject,
                    _id: conversation._id
                  };
                }

                this.historyConversations.push(formattedConversation);
              }
            });
            this.loadingClientData = false;
            this.loadingClientDataError = false;
          })
          .catch(err => {
            this.loadingClientData = false;
            this.loadingClientDataError = true;
          });
        this.$refs["showClientContactDetailsModal"].show();
      });
    },
    closeClientContactDetailsModal() {
      this.$refs["showClientContactDetailsModal"].hide();
    },
    filterSlotListSearchField(baseSlots, searchParts) {
      return baseSlots.filter(slot => {
        for (const searchPart of searchParts) {
          if (searchPart) {
            const scheduled = "gebucht";
            const free = "verfügbar";
            const completed = "Beendet";
            const unpaidCompanion = "Zahlung ausstehend";
            const refunded = "Erstattet";
            const failed = "Fehlgeschlagen";
            const cancelled = "Abgebrochen";
            if (
              !(
                moment(slot.date)
                  .format("DD.MM.YYYY")
                  .toLowerCase()
                  .includes(searchPart) ||
                moment(slot.endDate)
                  .format("DD.MM.YYYY")
                  .toLowerCase()
                  .includes(searchPart) ||
                slot.time.toLowerCase().includes(searchPart) ||
                /* slot.endTime.toLowerCase().includes(searchPart) ||*/
                slot.comment.toLowerCase().includes(searchPart) ||
                slot.client.toLowerCase().includes(searchPart) ||
                (slot.free && free.toLowerCase().includes(searchPart)) ||
                /* (!slot.free && scheduled.toLowerCase().includes(searchPart)) ||*/
                (slot.status === "scheduled" &&
                  scheduled.toLowerCase().includes(searchPart)) ||
                (slot.status === "completed" &&
                  completed.toLowerCase().includes(searchPart)) ||
                (slot.status === "unpaid" &&
                  unpaidCompanion.toLowerCase().includes(searchPart)) ||
                (slot.status === "refunded" &&
                  refunded.toLowerCase().includes(searchPart)) ||
                (slot.status === "failed" &&
                  failed.toLowerCase().includes(searchPart)) ||
                (slot.status === "cancelled" &&
                  cancelled.toLowerCase().includes(searchPart))
              )
            ) {
              return false;
            }
          }
        }
        return true;
      });
    },
    filterSlots(slots) {
      const sortedArray = slots.sort(
        (a, b) =>
          parseInt(
            moment(b.date + b.time, "YYYY-MM-DD HH:mm").format("YYYYMMDDHHmm")
          ) -
          parseInt(
            moment(a.date + a.time, "YYYY-MM-DD HH:mm").format("YYYYMMDDHHmm")
          )
      );
      return sortedArray;
    },
    openEditAppointmentModal(conversation) {
      this.isEditAppointment = true;
      const formattedMassModalSlots = {
        _id: conversation._id,
        date: conversation.date,
        endDate: conversation.endDate,
        time: conversation.time,
        endTime: conversation.endTime,
        free: false
      };
      this.massModalSlots = [formattedMassModalSlots];
      this.conversationForClientAction = conversation.conversation;
      this.$refs["editAppointmentModal"].show();
    },
    closeEditAppointmentModal() {
      this.isEditAppointment = false;
      this.$refs["editAppointmentModal"].hide();
    },
    openCancelAppointmentModal(conversation) {
      this.isCancelAppointment = true;
      this.cancelAppointment = conversation;
      this.$refs["cancelAppointmentModal"].show();
    },
    commitCancelAppointmentModal() {
      conversationsApi
        .cancelConversation(this.cancelAppointment.conversation._id)
        .then(() => {
          this.$router.push("/dashboard");
        })
        .finally(() => {
          this.isCancelAppointment = false;
          this.cancelAppointment = null;
          this.closeCancelAppointmentModal();
        });
    },
    closeCancelAppointmentModal() {
      this.isCancelAppointment = false;
      this.cancelAppointment = null;
      this.$refs["cancelAppointmentModal"].hide();
    },
    conversationSavedError() {
      this.isReloadBookingSelection = !this.isReloadBookingSelection;
      this.closeEditAppointmentModal();
      this.$emit("calendarChangedSuccess");
    },
    conversationSavedSuccess() {
      this.isReloadBookingSelection = !this.isReloadBookingSelection;
      this.closeEditAppointmentModal();
      this.$emit("calendarChangedError");
    },
    startZoomConversation(conversation) {
      window.open(conversation.zoomMeeting.startUrl);
    },
    isShowZoomMeetingTimeCorrect(conversation) {
      const today = moment().format("YYYY-MM-DD");
      const toDayTime = moment().format("HH:mm");
      const toDayTimeFormatted = moment(toDayTime, "HH:mm");
      if (moment(conversation.date).diff(moment(today)) >= 0) {
        if (
          moment(conversation.time, "HH:mm").diff(toDayTimeFormatted) >
            -1200000 &&
          moment(conversation.time, "HH:mm").diff(toDayTimeFormatted) < 1200000
        ) {
          // 1200000 ms === 20 min
          // 3600000 ms === 60 min
          return true;
        }
      }
      return false;
    },
    openConversationDetailsModal(conversation) {
      this.chosenHistoryConversation = conversation;
      this.$refs["conversationDetailsModal"].show();
    },
    closeConversationDetailsModal() {
      this.chosenHistoryConversation = null;
      this.$refs["conversationDetailsModal"].hide();
    },
    showEditModal(sIdx) {
      const slotIndex = parseInt(sIdx, 10);
      this.massModalSlots = [this.shownSlots[slotIndex]];
      this.$refs["editModal"].show();
      this.slotFormOpen = true;
    },
    slotsChanged() {
      this.massModalSlots = [];
      this.isReloadSlots = !this.isReloadSlots;
      //this.$emit("slotsChanged");
      if (!this.areSlotsGiven) {
        this.loadSlots();
      }
    },
    setSearchIdentifier(slotId, identifier) {
      this.searchIdentifiers[slotId] = identifier;
    },
    loadSlots() {
      if (!this.filterDate) {
        this.slots = [];
        this.loading = true;
        this.loadingError = false;
        cmApi.slot
          .getAllTimeSlots(this.currentUser._id)
          .then(res => {
            this.loading = false;
            this.loadingError = false;
            const timeSlots = res.data.data;
            timeSlots.forEach(slot => {
              const convertedUtcSlot = convertToLocal(
                slot.date,
                slot.endDate,
                slot.time,
                slot.endTime
              );
              const payload = {
                date: convertedUtcSlot.date,
                endDate: convertedUtcSlot.endDate,
                time: convertedUtcSlot.time,
                endTime: convertedUtcSlot.endTime,
                free: slot.free,
                _id: slot._id,
                metadata: slot.metadata
              };
              this.slots.push(payload);
            });
          })
          .catch(err => {
            this.loading = false;
            this.loadingError = true;
          });
        return;
      }
      this.slots = [];
      this.loading = true;
      this.loadingError = false;
      cmApi.slot
        .getAllTimeSlots(this.currentUser._id)
        .then(res => {
          this.loading = false;
          this.loadingError = false;
          const timeSlots = res.data.data;
          if (this.filterDate) {
            timeSlots.forEach(slot => {
              if (
                moment(slot.date, "YYYY-MM-DD").format("YYYY-MM-DD") ===
                moment(this.filterDate, "YYYY-MM-DD").format("YYYY-MM-DD")
              ) {
                const convertedUtcSlot = convertToLocal(
                  slot.date,
                  slot.endDate,
                  slot.time,
                  slot.endTime
                );
                const payload = {
                  date: convertedUtcSlot.date,
                  endDate: convertedUtcSlot.endDate,
                  time: convertedUtcSlot.time,
                  endTime: convertedUtcSlot.endTime,
                  free: slot.free,
                  _id: slot._id,
                  metadata: slot.metadata
                };
                this.slots.push(payload);
              }
            });
          } else {
            this.slots = timeSlots;
          }
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    },
    loadConversations(isPagination) {
      this.loading = true;
      this.loadingError = false;
      this.allConversations = [];
      conversationsApi
        .listConversations({
          params: {
            sortField: "updateTimeStamp",
            sortDir: "desc"
          }
        })
        .then(res => {
          const conversations = res.data.data;
          this.allConversations = [];
          conversations.forEach(slot => {
            const convertUtc = convertToLocal(
              slot.appointment.date,
              slot.appointment.endDate,
              slot.appointment.time,
              slot.appointment.endTime
            );
            const appointment = {
              date: convertUtc.date,
              endDate: convertUtc.endDate,
              time: convertUtc.time,
              endTime: convertUtc.endTime,
              _id: slot.appointment._id
            };
            let zoomMeeting = null;
            if (slot.zoomMeeting) {
              zoomMeeting = {
                _id: slot.zoomMeeting._id,
                startUrl: slot.zoomMeeting.startUrl,
                joinUrl: slot.zoomMeeting.joinUrl,
                duration: slot.zoomMeeting.duration,
                recordingReceived: slot.zoomMeeting.recordingReceived
              };
            }
            let payload;
            if (slot.transactions) {
              payload = {
                transactions: slot.transactions,
                zoomMeeting: zoomMeeting,
                appointment: appointment,
                attendees: slot.attendees,
                createTimeStamp: slot.createTimeStamp,
                host: slot.host,
                status: slot.status,
                subject: slot.subject,
                updateTimeStamp: slot.updateTimeStamp,
                type: slot.type,
                _id: slot._id
              };
            } else {
              payload = {
                zoomMeeting: zoomMeeting,
                appointment: appointment,
                attendees: slot.attendees,
                createTimeStamp: slot.createTimeStamp,
                host: slot.host,
                status: slot.status,
                subject:
                  slot.type === EConversationTypes.ONE_ON_ONE
                    ? slot.subject
                    : slot.appointment.metadata.isFollowupSlot
                    ? this.$t("conversations.gomSubjectFollowup")
                    : this.$t("conversations.gomSubject"),
                updateTimeStamp: slot.updateTimeStamp,
                type: slot.type,
                _id: slot._id
              };
            }
            this.allConversations.push(payload);
          });
          this.loading = false;
          this.loadingError = false;
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    },
    filterDateChanged() {
      if (this.filterDate) {
        const baseMoment = moment(this.filterDate);
        baseMoment.add(13, "hours");
        this.filterDate = baseMoment.toDate();
        this.loadSlots();
      }
    },
    selectCalendar(calendar) {
      this.calendar = calendar;
      this.loadSlots();
    },
    initComponent() {
      if (this.theSlots && this.theSlots.length > 0) {
        this.areSlotsGiven = true;
        this.isTheSlotsEmpty = false;
        this.slots = this.theSlots;
        this.loadConversations();
      } else {
        this.isTheSlotsEmpty = true;
        this.localFreeFilter = "future";
        if (this.settingForDate) {
          this.forDate = this.settingForDate;
        }
        if (this.settingFree === false || this.settingFree === true) {
          this.free = this.settingFree;
        }
        this.loadSlots();
        this.loadConversations();
      }
    }
  },
  created() {
    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
    }

    if (!this.theCalendars) {
      this.calendars = [];
      this.calendars = this.dummyCalendarData;
      this.initComponent();
    } else {
      this.calendars = this.theCalendars;
      this.initComponent();
    }
  }
};
</script>

<style scoped></style>
