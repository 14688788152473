<template>
  <div>
    <table id="style-2" class="table style-3  table-hover">
      <thead>
        <tr>
          <th>
            {{ $t("conversations.userDetailsModal.title") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="tr-border-top-grey">
          <table class="table-vertical-align">
            <tr>
              <td
                class="border-0 font-weight-bold td-table-mobile td-first-col-mobile"
              >
                <span>{{ $t("userDetails.name") }}: </span>
              </td>
              <td class="border-0 td-table-mobile td-first-col-mobile">
                <div class="d-flex">
                  <div
                    style="width: 35px;"
                    class="usr-img-frame mr-2 rounded-circle"
                  >
                    <img
                      alt="avatar"
                      class="img-fluid rounded-circle"
                      :src="chosenUser.profile.picture"
                    />
                  </div>
                  <p class="align-self-center mb-0">
                    {{ displayUsernameToEmail(chosenUser.profile) }}
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td class="border-0 font-weight-bold td-table-mobile">
                <span>{{ $t("userDetails.email") }}: </span>
              </td>
              <td class="border-0 td-table-mobile">
                <span>{{ chosenUser.profile.email }}</span>
              </td>
            </tr>
            <tr>
              <td class="border-0 font-weight-bold td-table-mobile">
                <span>{{ $t("userDetails.phoneNumber") }}: </span>
              </td>
              <td class="border-0 td-table-mobile">
                <span v-if="chosenUser.profile.phoneNumber">{{
                  chosenUser.profile.phoneNumber
                }}</span>
                <span v-else>-</span>
              </td>
            </tr>
          </table>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import screenSizeMixin from "@/mixins/screenSize";
import { displayUsernameToEmail } from "@/utils/userHelper";

export default {
  name: "UsersDetailsTable",
  mixins: [screenSizeMixin],
  props: {
    chosenUser: Object
  },
  data() {
    return {
      displayUsernameToEmail
    };
  }
};
</script>

<style scoped></style>
