<template>
  <div v-if="chosenSlot.free">
    <b-badge class="bg-success font-size-2 shadow-none">
      <span> {{ $t("calendarModule.slotForm.labels.isNotBooked") }}</span>
    </b-badge>
  </div>
  <div v-else-if="!chosenSlot.free">
    <div v-if="chosenSlot.status === 'scheduled'">
      <b-badge class="bg-warning font-size-2 shadow-none">
        <span> {{ $t("calendarModule.slotForm.labels.isBooked") }}</span>
      </b-badge>
    </div>
    <div v-else-if="chosenSlot.status === 'unpaid'">
      <b-badge class="bg-danger font-size-2 shadow-none">
        <span> {{ $t("conversations.status.unpaidCompanion") }}</span>
      </b-badge>
    </div>
    <div v-else-if="chosenSlot.status === 'completed'">
      <b-badge class="bg-success font-size-2 shadow-none">
        <span> {{ $t("conversations.status.completed") }}</span>
      </b-badge>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatusColumn",
  props:{
    chosenSlot: Object,
  }
};
</script>

<style scoped></style>
