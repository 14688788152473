<template>
  <div>
    <table
      v-if="
        !isMobileScreenSize &&
          !isModalMobileScreenSize &&
          (!isTabletScreenSize || !isSidebarOpen)
      "
      id="style-3"
      class="table style-3  table-hover"
    >
      <thead>
        <tr>
          <th>
            {{ $t("conversations.status.title") }}
          </th>
          <th>
            {{ $t("conversations.type") }}
          </th>
          <th>
            {{ $t("conversations.date") }}
          </th>
          <th>
            {{ $t("conversations.comment") }}
          </th>
          <th>
            {{ $t("conversations.client") }}
          </th>
          <th />
          <th v-if="withEdit && editInList" />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(slot, sIdx) in shownSlots" :key="sIdx">
          <td v-if="slot.status !== 'pending' && slot.status !== 'cancelled'">
            <div v-if="slot.free">
              <b-badge class="bg-success font-size-2 shadow-none">
                <span>
                  {{ $t("calendarModule.slotForm.labels.isNotBooked") }}</span
                >
              </b-badge>
            </div>
            <div v-else-if="!slot.free">
              <div v-if="slot.status === 'scheduled'">
                <b-badge class="bg-warning font-size-2 shadow-none">
                  <span>
                    {{ $t("calendarModule.slotForm.labels.isBooked") }}</span
                  >
                </b-badge>
              </div>
              <div v-else-if="slot.status === 'unpaid'">
                <b-badge class="bg-danger font-size-2 shadow-none">
                  <span> {{ $t("conversations.status.unpaidCompanion") }}</span>
                </b-badge>
              </div>
              <div v-else-if="slot.status === 'completed'">
                <b-badge class="bg-success font-size-2 shadow-none">
                  <span> {{ $t("conversations.status.completed") }}</span>
                </b-badge>
              </div>
            </div>
          </td>
          <td class="table-column-min-width-160px" v-if="slot.status !== 'pending' && slot.status !== 'cancelled'">
            <span>{{slot.type ?  $t(`conversations.types.${slot.type}`) : $t(`conversations.types.${slot.metadata.type}`) }}</span>
          </td>
          <td
            v-if="slot.status !== 'pending' && slot.status !== 'cancelled'"
            class="table-column-min-width-160px"
          >
            <span
              >{{ moment(slot.date).format("DD.MM.YYYY") }} -
              {{ slot.time }}</span
            >
          </td>
          <td v-if="slot.status !== 'pending' && slot.status !== 'cancelled'">
            <span
              class="break-word"
              :class="
                slot.comment.length > 70 ? 'restrict-paragraph-two-lines' : ''
              "
            >
              {{ slot.comment }}
            </span>
            <div
              v-if="
                (isTabletScreenSize || isSmDesktopScreenSize) &&
                  slot.comment.length > 70
              "
              class="text-center mt-3"
            >
              <img
                class="feathericons-plus-circle bg-primary p-3 cursor-pointer"
                @click.prevent="openShowUserCommentModal(slot.comment)"
              />
              <span
                class="color-primary cursor-pointer"
                @click.prevent="openShowUserCommentModal(slot.comment)"
              >
                {{ $t("actions.showUserComment") }}
              </span>
            </div>
          </td>
          <td v-if="slot.status !== 'pending' && slot.status !== 'cancelled'">
            <div v-if="slot.conversation">
              <span
                v-for="(attendee, attendeeIdx) in slot.conversation.attendees"
                :key="attendeeIdx"
              >
                <span v-if="attendeeIdx > 0">, </span>
                <span
                  class="btn bg-transparent shadow-none border-0 p-0"
                  @click.prevent="openUserDetailsModal(attendee._id)"
                >
                  {{ displayUsernameToEmail(attendee.profile) }}</span
                >
              </span>
            </div>
            <div v-else>
              <span>-</span>
            </div>
          </td>
          <!-- <td
            v-if="
              !slot.free &&
                slot.status !== 'pending' &&
                slot.status !== 'cancelled'
            "
          >
            <button
              @click="emitShowClientContactDetailsModal(slot)"
              class="btn btn-outline-primary"
            >
              {{ $t("misc.showClientData") }}
            </button>
          </td>-->
          <td
            v-if="
              withEdit &&
                editInList &&
                localFreeFilter !== 'past' &&
                slot.status !== 'pending' &&
                slot.status !== 'cancelled'
            "
          >
            <div v-if="!slot.free">
              <div v-if="slot.status === 'scheduled'">
                <div v-if="slot.zoomMeeting">
                  <div
                    v-if="isShowZoomMeetingTimeCorrect(slot)"
                    class="text-center"
                  >
                    <button
                      @click="startZoomConversation(slot)"
                      class="btn btn-outline-warning w-100"
                    >
                      {{ $t("conversations.tableButtons.joinAppointment") }}
                    </button>
                  </div>
                  <div v-else class="text-center">
                    <button
                      v-if="withEdit"
                      @click="emitOpenEditAppointmentModal(slot)"
                      class="btn btn-outline-warning w-100"
                    >
                      {{
                        $t("conversations.tableButtons.rescheduleAppointment")
                      }}
                    </button>
                  </div>
                </div>
                <div v-else class="text-center">
                  <button
                    v-if="withEdit"
                    @click="emitOpenEditAppointmentModal(slot)"
                    class="btn btn-outline-warning w-100"
                  >
                    {{ $t("conversations.tableButtons.rescheduleAppointment") }}
                  </button>
                  <button
                    @click="emitOpenCancelAppointmentModal(slot)"
                    class="btn btn-outline-danger w-100 mt-2"
                  >
                    {{ $t("conversations.tableButtons.cancelAppointment") }}
                  </button>
                </div>
              </div>
              <div
                v-else-if="
                  slot.status === 'unpaid' || slot.status === 'completed'
                "
                class="text-center"
              >
                <button
                  v-if="withEdit"
                  @click="emitOpenConversationDetailsModal(slot)"
                  class="btn btn-outline-success w-100"
                >
                  {{ $t("conversations.tableButtons.showAppointment") }}
                </button>
              </div>
              <div class="text-center mt-2">
                <button
                  @click="emitShowClientContactDetailsModal(slot)"
                  class="btn btn-outline-primary w-100"
                  v-if="slot && slot.type !== 'gom'"
                >
                  {{ $t("misc.showClientData") }}
                </button>
              </div>
            </div>
            <div v-else-if="slot.free">
              <button
                v-if="withEdit"
                @click="emitShowEditModal(sIdx)"
                class="btn btn-outline-success"
              >
                {{ $t("conversations.tableButtons.rescheduleAppointment") }}
              </button>
            </div>
          </td>
          <td
            v-if="
              localFreeFilter === 'past' &&
                slot.status !== 'cancelled' &&
                slot.status !== 'pending'
            "
          >
            <div class="text-center">
              <button
                class="btn btn-outline-primary w-100"
                @click="emitOpenConversationDetailsModal(slot)"
              >
                {{ $t("misc.showAppointment") }}
              </button>
            </div>
            <div class="text-center mt-2">
              <button
                @click="emitShowClientContactDetailsModal(slot)"
                class="btn btn-outline-primary w-100"
                v-if="slot && slot.type !== 'gom'"
              >
                {{ $t("misc.showClientData") }}
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table v-else id="style-2" class="table style-3  table-hover">
      <thead>
        <tr>
          <th>
            {{ $t("conversations.myConversations") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="tr-border-top-grey p-0"
          v-for="(slot, sIdx) in shownSlots"
          :key="sIdx"
        >
          <td class="p-0">
            <table
              v-if="slot.status !== 'pending' && slot.status !== 'cancelled'"
              style="width: 100%"
            >
              <tr>
                <td
                  class="col-3 border-0 font-weight-bold td-table-mobile td-first-col-mobile w-20-percent"
                >
                  <span>{{ $t("conversations.status.title") }}: </span>
                </td>
                <td class="col-9 border-0 td-table-mobile td-first-col-mobile">
                  <StatusColumn :chosen-slot="slot" />
                </td>
              </tr>
              <tr>
                <td
                  class="col-3 border-0 font-weight-bold td-table-mobile w-20-percent"
                >
                  <span>{{ $t("conversations.date") }}: </span>
                </td>
                <td class="col-9 border-0 td-table-mobile">
                  <span
                    >{{ moment(slot.date).format("DD.MM.YYYY") }} -
                    {{ slot.time }}</span
                  >
                </td>
              </tr>
              <tr>
                <td
                  class="col-3 border-0 font-weight-bold td-table-mobile w-20-percent"
                >
                  <span>{{ $t("conversations.comment") }}: </span>
                </td>
                <td class="col-9 border-0 td-table-mobile break-word">
                  <span class="restrict-paragraph-two-lines">
                    {{ slot.comment }}
                  </span>
                  <div v-if="slot.comment.length > 70">
                    <img
                      class="feathericons-plus-circle bg-primary p-3 cursor-pointer"
                      @click.prevent="openShowUserCommentModal(slot.comment)"
                    />
                    <span
                      class="color-primary cursor-pointer"
                      @click.prevent="openShowUserCommentModal(slot.comment)"
                    >
                      {{ $t("actions.showUserComment") }}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  class="col-3 border-0 font-weight-bold td-table-mobile w-20-percent"
                >
                  <span>{{ $t("conversations.client") }}: </span>
                </td>
                <td class="col-9 border-0 td-table-mobile">
                  <div v-if="slot.conversation">
                    <span
                      v-for="(attendee, attendeeIdx) in slot.conversation
                        .attendees"
                      :key="attendeeIdx"
                    >
                      <span v-if="attendeeIdx > 0">, </span>
                      <span
                        class="btn bg-transparent shadow-none border-0 p-0"
                        @click.prevent="openUserDetailsModal(attendee._id)"
                      >
                        {{ displayUsernameToEmail(attendee.profile) }}</span
                      >
                    </span>
                  </div>
                  <div v-else>
                    <span>-</span>
                  </div>
                </td>
              </tr>
            </table>

            <div
              class="text-center ml-auto mr-auto pb-4 pt-4 pl-3 pr-3"
              style="width: 100%;"
              v-if="
                !slot.free &&
                  slot.status !== 'pending' &&
                  slot.status !== 'cancelled'
              "
            >
              <button
                @click="emitShowClientContactDetailsModal(slot)"
                class="btn btn-outline-primary w-100 ml-auto mr-auto"
                v-if="slot && slot.type !== 'gom'"
              >
                {{ $t("misc.showClientData") }}
              </button>
            </div>
            <div
              class="text-center ml-auto mr-auto pb-4 pl-3 pr-3"
              style="width: 100%;"
              v-if="
                withEdit &&
                  editInList &&
                  localFreeFilter !== 'past' &&
                  slot.status !== 'pending'
              "
            >
              <div v-if="!slot.free">
                <div v-if="slot.status === 'scheduled'">
                  <div v-if="slot.zoomMeeting">
                    <div v-if="isShowZoomMeetingTimeCorrect(slot)">
                      <button
                        @click="startZoomConversation(slot)"
                        class="btn btn-outline-warning w-100 ml-auto mr-auto"
                      >
                        {{ $t("conversations.tableButtons.joinAppointment") }}
                      </button>
                    </div>
                    <div v-else>
                      <button
                        v-if="withEdit"
                        @click="emitOpenEditAppointmentModal(slot)"
                        class="btn btn-outline-warning w-100 ml-auto mr-auto"
                      >
                        {{
                          $t("conversations.tableButtons.rescheduleAppointment")
                        }}
                      </button>
                    </div>
                  </div>
                  <div v-else>
                    <button
                      v-if="withEdit"
                      @click="emitOpenEditAppointmentModal(slot)"
                      class="btn btn-outline-warning w-100 ml-auto mr-auto"
                    >
                      {{
                        $t("conversations.tableButtons.rescheduleAppointment")
                      }}
                    </button>
                    <button
                      @click="emitOpenCancelAppointmentModal(slot)"
                      class="btn btn-outline-danger w-100 ml-auto mr-auto mt-2"
                    >
                      {{
                        $t("conversations.tableButtons.cancelAppointment")
                      }}
                    </button>
                  </div>
                </div>
                <div
                  v-else-if="
                    slot.status === 'unpaid' || slot.status === 'completed'
                  "
                >
                  <button
                    v-if="withEdit"
                    @click="emitOpenConversationDetailsModal(slot)"
                    class="btn btn-outline-success w-100 ml-auto mr-auto"
                  >
                    {{ $t("conversations.tableButtons.showAppointment") }}
                  </button>
                </div>
              </div>
              <div v-else-if="slot.free">
                <button
                  v-if="withEdit"
                  @click="emitShowEditModal(sIdx)"
                  class="btn btn-outline-success w-100 ml-auto mr-auto"
                >
                  {{ $t("conversations.tableButtons.rescheduleAppointment") }}
                </button>
              </div>
            </div>
            <div
              class="text-center ml-auto mr-auto pb-4 pl-3 pr-3"
              style="width: 100%;"
              v-if="
                localFreeFilter === 'past' &&
                  slot.status !== 'cancelled' &&
                  slot.status !== 'pending'
              "
            >
              <button
                class="btn btn-outline-primary w-100 ml-auto mr-auto"
                @click="emitOpenConversationDetailsModal(slot)"
              >
                {{ $t("misc.showAppointment") }}
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- Show Comment Modal -->
    <b-modal
      ref="showUserCommentModal"
      size="lg"
      :title="$t('modals.showUserCommentModal.title')"
      hide-footer
      @hide="closeShowUserCommentModal()"
    >
      <b-row>
        <b-col>
          <p v-if="userComment" class="break-word">
            {{ userComment }}
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <button
            class="btn btn-outline-primary"
            @click.prevent="closeShowUserCommentModal()"
          >
            {{ $t("misc.close") }}
          </button>
        </b-col>
      </b-row>
    </b-modal>
    <!-- User Details Modal -->
    <b-modal
      :title="$t('conversations.userDetailsModal.title')"
      ref="userDetailsModal"
      hide-footer
      @close="closeUserDetailsModal()"
    >
      <p>{{ $t("conversations.userDetailsModal.description") }}</p>
      <div>
        <UsersDetailsTable :chosen-user="chosenUser" />
      </div>
      <button
        @click.prevent="closeUserDetailsModal()"
        class="btn btn-outline-success mb-2 mr-2"
      >
        {{ $t("misc.close") }}
      </button>
    </b-modal>
  </div>
</template>

<script>
import * as moment from "moment";
import { EUserRoles } from "@/store/modules/users/EUserRoles";
import { displayUsernameToEmail } from "../../../utils/userHelper";
import StatusColumn from "@/components/calendarModule/common/SlotListTableColumns/StatusColumn";
import UsersDetailsTable from "@/components/users/UsersDetailsTable";
import * as userApi from "../../../store/modules/users/users.api";
import { mapGetters } from "vuex";

export default {
  name: "SlotListTable",
  components: { UsersDetailsTable, StatusColumn },
  props: {
    isMobileScreenSize: Boolean,
    isModalMobileScreenSize: Boolean,
    isTabletScreenSize: Boolean,
    isSmDesktopScreenSize: Boolean,
    withEdit: Boolean,
    editInList: Boolean,
    shownSlots: Array,
    localFreeFilter: [Boolean, String],
    showClientContactDetailsModal: Function,
    openEditAppointmentModal: Function,
    openConversationDetailsModal: Function,
    openCancelAppointmentModal: Function,
    showEditModal: Function
  },
  data() {
    return {
      loadingUserData: false,
      loadingUserDataError: false,
      moment,
      EUserRoles,
      displayUsernameToEmail,
      chosenUser: null,
      userComment: null
    };
  },
  computed: {
    ...mapGetters("sideBar", ["isSidebarOpen"])
  },
  methods: {
    openShowUserCommentModal(userComment) {
      this.userComment = userComment;
      this.$refs["showUserCommentModal"].show();
    },
    closeShowUserCommentModal() {
      this.userComment = null;
      this.$refs["showUserCommentModal"].hide();
    },
    closeUserDetailsModal() {
      this.$refs["userDetailsModal"].hide();
    },
    openUserDetailsModal(userId) {
      this.loadingUserData = true;
      this.loadingUserDataError = false;
      userApi
        .getUser(userId)
        .then(res => {
          const user = res.data.data;
          this.chosenUser = user;
          this.loadingUserData = false;
          this.loadingUserDataError = false;
          this.$refs["userDetailsModal"].show();
        })
        .then(err => {
          this.loadingUserData = false;
          this.loadingUserDataError = true;
        });
    },
    emitShowClientContactDetailsModal(slot) {
      this.showClientContactDetailsModal(slot);
    },
    emitOpenEditAppointmentModal(slot) {
      this.openEditAppointmentModal(slot);
    },
    emitOpenCancelAppointmentModal(slot) {
      this.openCancelAppointmentModal(slot);
    },
    emitOpenConversationDetailsModal(slot) {
      this.openConversationDetailsModal(slot);
    },
    emitShowEditModal(slot) {
      this.showEditModal(slot);
    },
    startZoomConversation(conversation) {
      window.open(conversation.zoomMeeting.startUrl);
    },
    isShowZoomMeetingTimeCorrect(conversation) {
      const today = moment().format("YYYY-MM-DD");
      const toDayTime = moment().format("HH:mm");
      const toDayTimeFormatted = moment(toDayTime, "HH:mm");
      if (moment(conversation.date).diff(moment(today)) >= 0) {
        if (
          moment(conversation.time, "HH:mm").diff(toDayTimeFormatted) >
            -1200000 &&
          moment(conversation.time, "HH:mm").diff(toDayTimeFormatted) < 1200000
        ) {
          // 1200000 ms === 20 min
          // 3600000 ms === 60 min
          return true;
        }
      }
      return false;
    }
  }
};
</script>

<style scoped></style>
